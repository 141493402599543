 // import Sentry
 import * as Sentry from "@sentry/browser";
  // import Swiper bundle with all modules installed
  import Swiper from 'swiper/bundle';

  // import styles bundle
  import 'swiper/css/bundle';


 const swiper = new Swiper('.swiper', {
    direction: 'horizontal',
    slidesPerView: 1,
    loop:true,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    controller: {
        inverse: true,
    },
    autoplay: {
        delay: 5000,
    }
 });


window.Sentry = Sentry;
 Sentry.init({
   dsn: "https://fe8ab45952fc4059a3f9fda13df72242@o1231262.ingest.sentry.io/6378512",
   attachStacktrace: true,
   environment: "production",
   maxBreadcrumbs: 50,
   autoSessionTracking: true,
   enableTracing: true,
   replaysSessionSampleRate: 0.1,
   // If the entire session is not sampled, use the below sample rate to sample
   // sessions when an error occurs.
   replaysOnErrorSampleRate: 1.0,

   integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
   tracePropagationTargets: [
     window.location.hostname,
     /^https:\/\/apiv2\.aizen-algerie\.com\/api/,
   ],
 });